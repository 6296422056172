<template>
  <div class="pending-payment-domestic">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Pending Payment Domestic</h3>
            </div>

            <div class="card-body min-vh">
              <div class="search-area">
                 <div class="row justify-content-md-center">
                    <div class="col-sm-12 col-md-2 text-center mb-2">
                      <el-select 
                        v-model="query.filterType" 
                        placeholder="Select"
                        class="form-control-alternative">
                          <el-option label="All" value="all"></el-option>
                          <el-option label="Wait" value="wait"></el-option>
                          <el-option label="Approve" value="approve"></el-option>
                          <el-option label="Decline" value="decline"></el-option>
                          <el-option label="Error" value="error"></el-option>
                        </el-select>
                    </div>
                    <div class="col-sm-12 col-md-3 text-center mb-2">
                      <el-date-picker
                        v-model="dateSearch"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="yyyy-MM-dd"
                        class="form-control-alternative"
                      >
                      </el-date-picker>
                    </div>

                    <div class="col-sm-12 col-md-2 text-center">
                      <el-button slot="append" type="primary" v-loading="loading" icon="el-icon-search" @click="getPendingPayment()">Search</el-button>
                    </div>
                </div>
              </div>

              <el-table
              size="mini"
                border
                :data="dataTable"
                :loading="loading"
                :disabled="loading"
                style="width: 100%">
  
                <el-table-column
                  label="Case ID"
                  prop="caseId"
                  header-align="center" 
                  align="center"
                  width="100"
                  fixed="left"
                  >
                </el-table-column>
                <el-table-column
                  label="BankholderID"
                  prop="bankholderid"
                  header-align="center" 
                  align="center"
                  width="120"
                  fixed="left"
                  >
                </el-table-column>
                <el-table-column
                  label="Currency"
                  prop="currency"
                  header-align="center" 
                  align="center"
                  width="100"
                  >
                </el-table-column>
    
                <el-table-column
                  label="Amount"
                  prop="amount"
                  header-align="center" 
                  align="center"
                  min-width="120"
                  >
                </el-table-column>
                <el-table-column
                  label="Payment Type"
                  prop="paymentType"
                  header-align="center" 
                  align="center"
                  min-width="120"
                  >
                  <template slot-scope="scope">
                    <el-tag size="mini" :type="formatPaymentType[scope.row.paymentType]">{{scope.row.paymentType.toUpperCase()}}</el-tag> 
                  </template>
                </el-table-column>
                <el-table-column
                  label="Name"
                  prop="payee"
                  header-align="center" 
                  align="left"
                  min-width="250"
                  >
                </el-table-column>
                <el-table-column
                  label="Reference"
                  prop="reference"
                  header-align="center" 
                  align="left"
                  min-width="250"
                  >
                </el-table-column>
                <el-table-column
                  label="Reason"
                  prop="reason"
                  header-align="center" 
                  align="left"
                  min-width="250"
                  >
                </el-table-column>
                <el-table-column
                  label="Date Create"
                  prop="created"
                  header-align="center" 
                  align="center"
                  width="150"
                  >
                </el-table-column>
                <el-table-column
                  label="Date Update"
                  prop="updated"
                  header-align="center" 
                  align="center"
                  width="150"
                  >
                </el-table-column>
                <el-table-column
                  label="Status"
                  prop="status"
                  header-align="center" 
                  align="center"
                  fixed="right"
                  width="100"
                  >
                  <template slot-scope="scope">
                    <el-tag size="mini" :type="formatType[scope.row.status]">{{scope.row.status.toUpperCase()}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Action"
                  prop="uid"
                  header-align="center" 
                  align="center"
                  fixed="right"
                  width="100"
                  >
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="viewDetail(scope.row.uid)">View</el-button>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import PaymentApi from "@/api/payment";
import Utils from '@/utils'
export default {
  computed:{
    isBusiness() {
      return this.$store.getters.business;
    },
  },
  data(){
    return {
      loading: false,
      dataTable : [],
      dateSearch: [],
      query : {
        startdate: "",
        enddate: "",
        filterType: "wait"
      },
      formatType: {
        decline: "warning",
        wait: "info",
        approve: "success",
        error: "danger"
      },
      formatPaymentType: {
        payin: "success",
        payout: "warning"
      }
    }
  },
  methods:{
    getPendingPayment(){
      this.loading = true;
      this.query.startdate = Utils.dateFormat(this.dateSearch[0], "YYYY-MM-DD") || ""
      this.query.enddate = Utils.dateFormat(this.dateSearch[1], "YYYY-MM-DD") || ""
      PaymentApi.pendingPaymentDomestic(this.query).then(({result, data}) => {
        this.loading = false;
        if(result){
          this.dataTable = data.list
        }
      });
    },
    viewDetail(paymentUID){
      this.$router.push(`/pending-payment-domestic/detail/${paymentUID}`)
    }
  },
  mounted(){
    this.getPendingPayment()
  }
}
</script>

<style lang="scss">
.pending-payment-domestic{
  .search-area{
    margin-bottom: 16px;
  }
  .el-date-editor{
    min-width: 100%;
    .el-range-separator {
      width: 20%;
    }
  }
}
</style>